<script>
import { mapState, mapMutations } from "vuex";
import UrgencyStartForm from "@/components/ContractModals/Components/BaseParts/UrgencyStartForm";

export default {
  extends: UrgencyStartForm,
  computed: {
    ...mapState("PurchaseOrderSt2ContractModal", ["quantity", "textHint"])
  },
  methods: {
    ...mapMutations("PurchaseOrderSt2ContractModal", ["setQuantity", "setTextHint"])
  }
};
</script>
