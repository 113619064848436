<template>
  <i v-if="repairType" class="repair-type-ico blue lighten-1 pa-4 mr-4">
    <v-img
      v-if="
        repairType === 'diagnostic' && state !== 'warranty_requested' && state !== 'on_warranty'
      "
      src="@/assets/icons/expertise.svg"
      max-height="72px"
      max-width="72px"
    ></v-img>
    <v-img
      v-if="
        repairType !== 'diagnostic' && state !== 'warranty_requested' && state !== 'on_warranty'
      "
      src="@/assets/icons/repair.svg"
      max-height="72px"
      max-width="72px"
    ></v-img>
    <v-img
      v-if="state === 'warranty_requested' || state === 'on_warranty'"
      src="@/assets/icons/warranty.svg"
      max-height="72px"
      max-width="72px"
    ></v-img>
  </i>
</template>

<script>
export default {
  name: "RepairTypeIco",
  props: {
    state: String,
    repairType: String
  }
};
</script>

<style scoped>
.repair-type-ico {
  width: 72px;
  height: 72px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
</style>
