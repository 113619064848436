<script>
import { mapState, mapMutations } from "vuex";
import LimitedWarranty from "@/components/ContractModals/Components/BaseParts/LimitedWarranty";

export default {
  extends: LimitedWarranty,
  computed: {
    ...mapState("PurchaseOrderSt2ContractModal", ["limitedWarranty"])
  },
  methods: {
    ...mapMutations("PurchaseOrderSt2ContractModal", [
      "setLimitedWarrantyLabourQuantity",
      "setLimitedWarrantyUnitsOne",
      "setLimitedWarrantyHourlyPrice",
      "setLimitedWarrantyUnitsTwo",
      "setLimitedWarrantyTerm"
    ])
  }
};
</script>
