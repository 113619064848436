<template>
  <div class="header-steps">
    <div :class="{ 'header-steps__step--active': step === 1 }" class="header-steps__step">
      <span>{{ $t("Step 1") }}</span>
    </div>
    <div :class="{ 'header-steps__step--active': step === 2 }" class="header-steps__step">
      <span>{{ $t("Step 2") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number
    }
  }
};
</script>

<style scoped>
.header-steps {
  display: flex;
  width: auto;
}
.header-steps__step {
  position: relative;
  display: block;
  padding: 10px;
  font-size: 14px;
  color: transparent;
}
.header-steps__step--active {
  color: #aeaeae;
}
.header-steps__step:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  display: block;
  height: 4px;
  background-color: #005fce;
  border-radius: 51px;
}
</style>
