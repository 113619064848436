<script>
import { mapState, mapMutations } from "vuex";
import SelectionForm from "@/components/ContractModals/Components/BaseParts/SelectionForm";

export default {
  extends: SelectionForm,
  computed: {
    ...mapState("PurchaseOrderSt2ContractModal", ["requestedServices"])
  },
  methods: {
    ...mapMutations("PurchaseOrderSt2ContractModal", [
      "addSelectedCondition",
      "delSelectedCondition",
      "setCurrentSelectedCondition",
      "setLabourQuantity",
      "setUnitsOne",
      "setHourlyPrice",
      "setUnitsTwo"
    ])
  }
};
</script>
