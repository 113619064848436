<template>
  <v-dialog v-model="isModalOpened" scrollable max-width="1245px">
    <v-card>
      <v-card-title class="contract-modal__title">
        <slot name="header"></slot>
      </v-card-title>

      <v-card-text>
        <form class="contract-modal__content" @submit.stop.prevent="$emit('formsubmitted')">
          <slot></slot>
          <div class="contract-modal__btn-wrapper">
            <slot name="terms"></slot>
            <button class="contract-modal__btn" :disabled="disableNext">
              {{ btnText }}
            </button>
          </div>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean
    },
    btnText: {
      type: String
    },
    disableNext: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isModalOpened: {
      get() {
        return this.showModal;
      },
      set(val) {
        this.$emit("closemodal", val); // for out modal clicks
      }
    }
  },
  data() {
    return {
      allFieldFilled: {
        "general-form": true,
        "urgency-start-form": true,
        "selection-form": true,
        "parts-materials-form": true,
        "limited-warranty-form": true,
        "hadnling-shipping-form": true
      }
    };
  }
};
</script>

<style>
.v-dialog > .v-card > .v-card__title.contract-modal__title {
  padding: 0;
}
</style>

<style scoped>
.contract-modal__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}
.contract-modal__btn-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.contract-modal__btn {
  display: inline-block;
  width: 230px;
  max-width: 100%;
  padding: 0 20px;
  height: 78px;
  line-height: 78px;
  font-size: 24px;
  color: #ffffff;
  background-color: #005fce;
  border-radius: 12px;
}
.contract-modal__btn:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
