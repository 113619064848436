<template>
  <v-container fluid no-gutters>
    <v-row>
      <v-col>
        <h3>{{ $t(`Contractors`) }}</h3>
        <v-divider class="mb-4" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-card class="quote-shadow">
          <v-card class="quote-shadow pdf-documents">
            <v-card class="quote-shadow main-info">
              <v-card
                class="pa-6 mb-6 quote-shadow quote-request"
                v-if="qouteRequest.performer_info"
              >
                <v-row no-gutters align="center" justify="space-between">
                  <v-col sm="1">
                    <v-avatar size="75" rounded>
                      <v-img
                        v-if="qouteRequest.performer_info.avatar_url"
                        alt="Avatar"
                        :src="qouteRequest.performer_info.avatar_url"
                      />
                      <v-icon v-else color="secondary">mdi-account</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col sm="2">
                    <p
                      class="v-chip--clickable expert-name font-weight-bold"
                      @click="goToPerformerProfile(qouteRequest)"
                    >
                      {{ qouteRequest.performer_info.name }}
                    </p>
                    <p v-if="qouteRequest.performer_info" class="mb-0">
                      <span class="text--disabled">{{ $t("Licenses") }}: </span>
                      <span class="pl-4">{{ qouteRequest.performer_info.licenses.length }}</span>
                    </p>
                  </v-col>
                  <v-divider vertical class="mx-4"></v-divider>
                  <v-col>
                    <table class="quote-request__table">
                      <tr>
                        <td class="quote-request__table-item text--disabled ">
                          {{ $t("Estimated cost, $") }}:
                        </td>
                        <td class="quote-request__table-item float-right font-weight-black pl-2">
                          ${{ estimatedCost | currency }}
                        </td>
                      </tr>
                      <tr>
                        <td class="quote-request__table-item text--disabled ">
                          {{ $t("Estimated service time") }}:
                        </td>
                        <td class="quote-request__table-item float-right font-weight-black pl-2">
                          {{ qouteRequest.urgency.date_value }} {{ qouteRequest.urgency.date_item }}
                        </td>
                      </tr>
                    </table>
                  </v-col>

                  <v-col
                    sm="3"
                    v-if="!qouteRequest.purchase_order_doc && !qouteRequest.service_report_doc"
                  >
                    <v-btn color="primary" block @click="openPurchaseStep1Modal">
                      {{ $t(`Send contract`) }}
                    </v-btn>
                  </v-col>
                  <v-col sm="3" v-if="withdrawPoAvailable">
                    <v-btn color="primary" block outlined @click="withdrawPO(qouteRequest.id)">
                      {{ $t(`Withdraw PO`) }}
                    </v-btn>
                  </v-col>

                  <!--  Selected  -->
                  <v-col v-if="isReplaceContractor">
                    <v-btn
                      outlined
                      color="primary"
                      :disabled="!isReplaceContractorAvailable"
                      class="mr-4"
                      @click="replaceUser(qouteRequest.id)"
                    >
                      {{ $t("Replace applicant") }}
                    </v-btn>
                  </v-col>
                  <!--  /Selected  -->

                  <v-col sm="2" v-if="qouteRequest.service_report_doc">
                    <v-btn
                      color="primary"
                      block
                      outlined
                      @click="viewServiceReport(qouteRequest.id)"
                    >
                      {{ $t(`View report`) }}
                    </v-btn>
                  </v-col>
                  <v-col sm="3" class="pl-2" v-if="showConfirmReport">
                    <v-btn color="primary" block @click="confirmReportDialog = true">
                      {{ $t(`Confirm report`) }}
                    </v-btn>
                  </v-col>
                  <v-col
                    sm="3"
                    class="pl-2"
                    v-if="isWarrantyButtonAvailable && doubledTicketIsExpert"
                  >
                    <v-btn color="primary" block @click="requestWarrantyDialog = true">
                      {{ $t(`Request warranty`) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="px-6">
                <v-col v-if="isShowContacts">
                  <ticket-chat-dialog :contractor="qouteRequest" :is_bid="false" />

                  <template v-if="qouteRequest.quotation_doc">
                    <span v-if="qouteRequest.quotation_doc.addresses.customer_address.skype">
                      <v-icon color="primary">mdi-skype</v-icon>
                      {{ qouteRequest.quotation_doc.addresses.customer_address.skype }}
                    </span>
                    <span v-if="qouteRequest.quotation_doc.addresses.customer_address.phone">
                      <v-icon color="primary">mdi-phone</v-icon>
                      {{ qouteRequest.quotation_doc.addresses.customer_address.phone }}
                    </span>
                    <span v-if="qouteRequest.quotation_doc.addresses.customer_address.email">
                      <v-icon color="primary">mdi-email</v-icon>
                      {{ qouteRequest.quotation_doc.addresses.customer_address.email }}
                    </span>
                  </template>
                  <div class="show-hide-dialog" v-if="showHideAvailable">
                    <span v-if="!isShowDocuments">
                      <a
                        @click="isShowDocuments = true"
                        class="arrow-right-slide arrow-right-slide_show"
                        >{{ $t("Show Documents") }}</a
                      >
                    </span>
                    <span v-else>
                      <a
                        @click="isShowDocuments = false"
                        class="arrow-right-slide arrow-right-slide_hide"
                        >{{ $t("Hide Documents") }}</a
                      >
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <pdf-documents :qouteRequest="qouteRequest" :isShow="isShowDocuments"></pdf-documents>
          </v-card>
          <v-row v-if="qouteRequest.service_report_doc" class="px-6">
            <v-col sm="7" class="d-flex align-center">
              <table class="quote-request__table">
                <tr>
                  <td class="quote-request__table-item text--disabled ">
                    <span>{{ $t("Total cost") }}</span>
                  </td>
                  <td class="quote-request__table-item float-right font-weight-black pl-2">
                    ${{ qouteRequest.service_report_doc.summary.total }}
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col sm="5" v-if="!qouteRequest.transaction_passed">
              <v-row v-if="qouteRequest.state === 'done'">
                <v-col sm="5" v-if="qouteRequest.service_report_doc">
                  <v-btn
                    color="primary"
                    outlined
                    block
                    :disabled="!invoice || !invoice.id"
                    @click="viewInvoice(qouteRequest.id)"
                  >
                    {{ $t(`View invoice`) }}
                  </v-btn>
                </v-col>
                <v-col sm="7" class="pl-2" v-if="qouteRequest.service_report_doc">
                  <v-btn
                    color="primary"
                    :disabled="!invoice || !invoice.id || !invoiceButtonActive"
                    @click="payInvoice(qouteRequest.id)"
                  >
                    {{ $t(`Pay invoice`) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- <span>Total cost</span>
            <span> </span> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="f-flex justify-end">
      <v-col sm="2">
        <v-btn outlined block class="float-right" @click="back">{{ $t("Back to the list") }}</v-btn>
      </v-col>
    </v-row>

    <!--  QUOTE MODALS  -->

    <purchase-order-st1-dialog
      :show-modal="isModalOpenedPurchaseOrderSt1"
      :data="qouteRequest"
      :btn-text="$t('Next')"
      @formsubmitted="switchStep2PurchaseModal"
    ></purchase-order-st1-dialog>
    <purchase-order-st2-dialog
      :show-modal="isModalOpenedPurchaseOrderSt2"
      :data="qouteRequest"
      :btn-text="$t('Sign and send')"
      @formsubmitted="catchPurchaseOrder"
    ></purchase-order-st2-dialog>

    <!-- Info Dialog -->

    <v-dialog v-model="successfullyDialog" max-width="290">
      <v-card>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-img max-width="40" class="ma-5" :src="require(`@/assets/icons/OK_icon.svg`)"></v-img>
          </div>
          <p class="text-center">{{ $t("Your invoice successfully payed!") }}</p>
          <p class="text-center">
            <router-link to="/projects/orders/new">{{ $t("Go to My projects") }}</router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successfullyWarranty" max-width="290">
      <v-card>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-img max-width="40" class="ma-5" :src="require(`@/assets/icons/OK_icon.svg`)"></v-img>
          </div>
          <p class="text-center">{{ $t("Warranty was requested!") }}</p>
          <p class="text-center">
            <router-link
              :to="{
                name: 'Ticket',
                params: { id: this.ticketData.id }
              }"
            >
              {{ $t("Go to My order ticket") }}
            </router-link>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" max-width="290">
      <v-card>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-img
              max-width="40"
              class="ma-5"
              :src="require(`@/assets/icons/ALERT_icon.svg`)"
            ></v-img>
          </div>
          <p class="text-center">{{ invoiceError }}</p>
          <!-- <p class="text-center">
            <router-link to="/projects/orders/new"></router-link>
          </p> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="requestWarrantyDialog" max-width="990">
      <v-card>
        <v-card-title>
          {{ $t("Add a new details") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <label class="pl-4 input-label">{{ $t("General description") }}</label>
              <v-icon>mdi-info-outline</v-icon>
              <v-textarea
                solo
                label="General description"
                v-model="warrantyForm.warranty_request.description"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="auto">
              <label class="input-label">
                {{ $t("General view") }}
                <v-icon class="float-right" @click="generalViewImg['asset_url'] = ''">
                  mdi-close
                </v-icon>
              </label>
              <v-card class="px-5 py-10" v-if="!generalViewImg['asset_url']">
                <!-- additional_info_items : {info_type: 'general_view'}  -->
                <v-text-field
                  solo
                  :label="$t('Title')"
                  :placeholder="$t('Title')"
                  v-model="generalViewImg['title']"
                ></v-text-field>

                <v-file-input
                  outlined
                  autofocus
                  full-width
                  hide-details
                  prepend-inner-icon="mdi-arrow-up"
                  :placeholder="$t('Upload image')"
                  prepend-icon=""
                  color="primary"
                  accept="image/png, image/jpeg, image/bmp"
                  @change="changeGeneralView"
                ></v-file-input>
              </v-card>
              <v-card v-if="generalViewImg['asset_url']">
                <v-img :src="generalViewImg['asset_url']" max-width="240" max-height="135"></v-img>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <label class="input-label">
                {{ $t("Name Plate") }}
                <v-icon class="float-right" @click="namePlateImg['asset_url'] = ''">
                  mdi-close
                </v-icon>
              </label>
              <v-card class="px-5 py-10" v-if="!namePlateImg['asset_url']">
                <!-- additional_info_items : {info_type: 'name_plate'}  -->
                <v-text-field
                  solo
                  :label="$t('Title')"
                  :placeholder="$t('Title')"
                  v-model="namePlateImg['title']"
                ></v-text-field>

                <v-file-input
                  outlined
                  autofocus
                  full-width
                  hide-details
                  prepend-inner-icon="mdi-arrow-up"
                  :placeholder="$t('Upload image')"
                  prepend-icon=""
                  color="primary"
                  accept="image/png, image/jpeg, image/bmp"
                  @change="changeNamePlate"
                >
                </v-file-input>
              </v-card>
              <v-card v-if="namePlateImg['asset_url']">
                <v-img :src="namePlateImg['asset_url']" max-width="240" max-height="135"></v-img>
              </v-card>
            </v-col>
            <v-col cols="auto">
              <label class="input-label">
                {{ $t("Failed unit") }}
                <v-icon class="float-right" @click="failedUnitImg['asset_url'] = ''">
                  mdi-close
                </v-icon>
              </label>
              <v-card class="px-5 py-10" v-if="!failedUnitImg['asset_url']">
                <!-- additional_info_items : {info_type: 'failed_unit'}  -->
                <v-text-field
                  solo
                  :label="$t('Title')"
                  :placeholder="$t('Title')"
                  v-model="failedUnitImg['title']"
                ></v-text-field>

                <v-file-input
                  outlined
                  autofocus
                  full-width
                  hide-details
                  prepend-inner-icon="mdi-arrow-up"
                  :placeholder="$t('Upload image')"
                  prepend-icon=""
                  color="primary"
                  accept="image/png, image/jpeg, image/bmp"
                  @change="changeFailedUnit"
                >
                </v-file-input>
              </v-card>
              <v-card v-if="failedUnitImg['asset_url']">
                <v-img :src="failedUnitImg['asset_url']" max-width="240" max-height="135"></v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col md="3">
              <v-btn color="primary" block @click="warranty">{{ $t(`Warranty request`) }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmReportDialog" max-width="740">
      <v-card>
        <v-card-title>
          {{ $t("Confirm report") }}
        </v-card-title>
        <v-card-text class="pa-5">
          <div v-if="ticketType === 'diagnostic'">
            <v-row justify="center">
              <v-col md="10" class="mr-4">
                <v-checkbox
                  class="mt-0"
                  hide-details
                  v-model="confirmReportForm.proceed_repairment"
                  :label="$t('I want to proceed with repairment')"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col md="10">
                <v-radio-group
                  class="mt-0 ml-3"
                  hide-details
                  v-model="confirmReportForm.same_performer"
                  :disabled="!confirmReportForm.proceed_repairment"
                >
                  <v-radio
                    v-if="!isWarrantyCase"
                    :value="true"
                    :label="$t('I want to continue with this expert')"
                  ></v-radio>
                  <v-radio
                    v-if="!isWarrantyCase"
                    :value="false"
                    :disabled="!canProceedWithRepairment"
                    :label="$t('I want to select other expert/technician')"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col md="8">
                <p v-if="isRequestAdditionalDiagnosticAvailable">
                  Press this button if you are not sure about diagnostic results and you want to
                  request additional diagnostics with another expert.
                </p>
                <p v-else>
                  No additional diagnostics are available for this case.
                </p>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col md="10">
                <v-btn
                  color="primary"
                  :disabled="!isRequestAdditionalDiagnosticAvailable"
                  block
                  @click="requestDiagnostic(qouteRequest.id)"
                  outlined
                >
                  {{ $t(`Request additional diagnostics`) }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-row justify="center">
            <v-col md="8">
              <v-checkbox
                value="false"
                aria-required="true"
                v-model="isConfirmedServiceReport"
                :label="
                  $t(
                    'By clicking “Confirm and continue” I confirm and sign the Service Report above'
                  )
                "
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="10">
              <v-btn
                color="primary"
                :disabled="!isConfirmedServiceReport"
                block
                @click="confirmServiceReport(qouteRequest.id)"
              >
                {{ $t(`Confirm and continue`) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import uid from "uid";
import { mapState, mapMutations, mapActions } from "vuex";
import TicketChatDialog from "@/components/TicketChat/TicketChatDialog";
import PurchaseOrderSt1 from "@/components/ContractModals/Modals/PurchaseOrderSt1";
import PurchaseOrderSt2 from "@/components/ContractModals/Modals/PurchaseOrderSt2";

import {
  getOneQuoteRequest,
  getInvoice,
  payInvoice,
  acceptServiceReport,
  declineUnsignedPO,
  requestWarranty,
  getPresignedLink,
  getServiceReportPDF,
  getInvoicePDF,
  replaceContractor
} from "@/api";
import PDFdocuments from "@/components/PDFdocuments.vue";

export default {
  components: {
    "pdf-documents": PDFdocuments,
    "ticket-chat-dialog": TicketChatDialog,
    "purchase-order-st1-dialog": PurchaseOrderSt1,
    "purchase-order-st2-dialog": PurchaseOrderSt2
  },
  data: () => ({
    quotationForTicket: {},
    noMoreAdditionalRequests: false,
    isConfirmedServiceReport: false,
    userProfiles: {},
    expertsList: [],
    qouteRequest: {},
    isShowDocuments: false,
    invoiceButtonActive: true,
    loading: false,
    invoice: null,
    ticketType: null,
    invoiceError: null,
    successfullyDialog: false,
    successfullyWarranty: false,
    confirmReportDialog: false,
    errorDialog: false,
    requestWarrantyDialog: false,
    confirmReportForm: {
      additional_requested: false,
      proceed_repairment: false,
      same_performer: true
    },
    generalViewImg: {
      title: "general view",
      asset_url: "",
      info_type: "general_view"
    },
    namePlateImg: {
      title: "name plate",
      asset_url: "",
      info_type: "name_plate"
    },
    failedUnitImg: {
      title: "failed unit",
      asset_url: "",
      info_type: "failed_unit"
    },
    warrantyForm: {
      ticket_id: null,
      warranty_request: {
        description: "Description of warranty request",
        additional_info_items: []
      }
    }
  }),
  methods: {
    ...mapActions("UsersListsStore", [
      "getQuotationForTicket",
      "sendSelectUser",
      "sendReplaceUser"
    ]),
    ...mapActions("PurchaseOrderSt2ContractModal", ["sendCreatePurchaseOrder"]),

    ...mapMutations({
      // step 1
      openPurchaseStep1Modal: "PurchaseOrderSt1ContractModal/openModal",

      closePurchaseStep1Modal: "PurchaseOrderSt1ContractModal/closeModal",
      setPurchaseOrderData: "PurchaseOrderSt1ContractModal/setPurchaseOrderData",
      clearPurchaseStep1ModalData: "PurchaseOrderSt1ContractModal/clearPurchaseOrderData",
      // step 2
      openPurchaseStep2Modal: "PurchaseOrderSt2ContractModal/openModal",
      closePurchaseStep2Modal: "PurchaseOrderSt2ContractModal/closeModal",
      clearPurchaseStep2ModalData: "PurchaseOrderSt2ContractModal/clearModalData",
      setTicketType: "InvoiceContractModal/setTicketType",
      setServiceCurrency: "InvoiceContractModal/setServiceCurrency"
    }),

    replaceUser(qid) {
      replaceContractor(qid, this.$route.params["id"])
        .then(() => {
          this.$router.push({ name: "Quotations" });
        })
        .catch(e => {
          console.error(e);
        });
    },

    // go to expert profile page
    goToPerformerProfile(q) {
      this.$router.push({
        name: "ShowUserProfile",
        params: { id: q.performer_info.id },
        query: { profile_view: "expert-profile" }
      });
    },

    // closeDialog() {},
    switchStep2PurchaseModal() {
      this.closePurchaseStep1Modal();
      this.openPurchaseStep2Modal();
    },

    refetchData() {
      this.getQuotationForTicket(this.$route.params["id"])
        .then(r => {
          this.quotationForTicket = r.data;
          this.expertsList = this.quotationForTicket.experts || [];
          const requestsDiagnosticList = this.expertsList.filter(
            u => u.additional_diagnostic_requested
          );
          this.noMoreAdditionalRequests =
            requestsDiagnosticList.length >= this.expertsList.length && this.expertsList.length > 0;
        })
        .catch(e => {
          console.error(e);
        });
    },

    getQouteRequest() {
      getOneQuoteRequest(this.$route.params["qid"])
        .then(r => {
          this.qouteRequest = r.data;
          this.ticketType = r.data.ticket_type;
          this.setTicketType(this.ticketType);
          this.setServiceCurrency(r.data.currency);
        })
        .catch(() => {
          this.$router.push({ name: "SearchExpert" });
        })
        .finally(() => (this.loading = false));
    },

    back() {
      this.$router.push({ name: "Quotations" });
    },

    redirectToTicketById(request) {
      if (Object.hasOwnProperty.call(request.data, "ticket_id")) {
        if (Number(request.data.ticket_id)) {
          document.location.href = `/ticket/${request.data.ticket_id}/`;
        }
      }
    },

    catchPurchaseOrder() {
      if (!this.termsChecked) {
        this.showPurchaseOrderErrors = true;
        this.purchaseOrderErrors = [this.$t("Terms agreement is required")];
        return;
      }
      this.showPurchaseOrderErrors = false;
      this.sendCreatePurchaseOrder(this.quoteId)
        .then(() => {
          this.clearPurchaseStep1ModalData();
          this.clearPurchaseStep2ModalData();
          this.closePurchaseStep1Modal();
          this.closePurchaseStep2Modal();
          // TODO: What is that? it gives error exception
          this.fetchData();
        })
        .catch(e => {
          if (
            Object.hasOwnProperty.call(e, "response") &&
            Object.hasOwnProperty.call(e.response, "data") &&
            Object.hasOwnProperty.call(e.response.data, "errors")
          ) {
            this.showPurchaseOrderErrors = true;
            this.purchaseOrderErrors = e.response.data.errors;
          } else {
            document.location.reload();
          }
        });
    },

    confirmServiceReport(qid) {
      this.confirmReportForm.additional_requested = false;
      const confirmReportForm = this.ticketType === "diagnostic" ? this.confirmReportForm : {};

      acceptServiceReport(qid, confirmReportForm)
        .then(r => {
          this.confirmReportDialog = false;
          this.getQouteRequest();
          this.redirectToTicketById(r);
        })
        .catch(e => {
          console.error(e);
          this.errorDialog = true;
          this.invoiceError = e.response.data.error || e.response.data.errors.join("\n");
        });
    },

    requestDiagnostic(qid) {
      this.confirmReportForm.additional_requested = true;
      this.confirmReportForm.same_performer = false;
      acceptServiceReport(qid, this.confirmReportForm)
        .then(r => {
          this.getQouteRequest();
          this.confirmReportDialog = false;
          this.redirectToTicketById(r);
        })
        .catch(e => {
          console.error(e);
          this.errorDialog = true;
          this.invoiceError = e.response.data.error || e.response.data.errors.join("\n");
        });
    },

    withdrawPO(qid) {
      declineUnsignedPO(qid)
        .then(() => {
          this.$router.push({ name: "Quotations" });
        })
        .catch(e => {
          console.error(e);
          this.errorDialog = true;
          this.invoiceError = e.response.data.error || e.response.data.errors.join("\n");
        });
    },

    payInvoice() {
      if (this.invoice) {
        this.invoiceButtonActive = false;
        payInvoice(this.invoice.id)
          .then(() => {
            this.getQouteRequest();
            this.successfullyDialog = true;
          })
          .catch(e => {
            console.error(e);
            this.errorDialog = true;
            this.invoiceButtonActive = true;
            this.invoiceError = e.response.data.error || e.response.data.errors.join("\n");
          });
      }
    },

    warranty() {
      this.warrantyForm.ticket_id = this.ticketData.id;
      if (this.namePlateImg["asset_url"]) {
        this.warrantyForm.warranty_request.additional_info_items.push(this.namePlateImg);
      }
      if (this.generalViewImg["asset_url"]) {
        this.warrantyForm.warranty_request.additional_info_items.push(this.generalViewImg);
      }
      if (this.failedUnitImg["asset_url"]) {
        this.warrantyForm.warranty_request.additional_info_items.push(this.failedUnitImg);
      }
      requestWarranty(this.warrantyForm)
        .then(() => {
          this.requestWarrantyDialog = false;
          this.successfullyWarranty = true;
        })
        .catch(e => {
          console.error("error", e);
        })
        .finally(() => {
          document.location.reload();
        });
    },

    changeGeneralView(file) {
      const fileParams = {
        file_name: uid(),
        file_type: file.name.split(".").pop(),
        file_instance_name: "order_assets"
      };
      getPresignedLink(fileParams, file)
        .then(res => {
          this.generalViewImg["asset_url"] = res.url.split("?")[0];
        })
        .catch(e => {
          console.error(e);
        });
    },

    changeNamePlate(file) {
      const fileParams = {
        file_name: uid(),
        file_type: file.name.split(".").pop(),
        file_instance_name: "order_assets"
      };
      getPresignedLink(fileParams, file)
        .then(res => {
          this.namePlateImg["asset_url"] = res.url.split("?")[0];
        })
        .catch(e => {
          console.error(e);
        });
    },

    changeFailedUnit(file) {
      const fileParams = {
        file_name: uid(),
        file_type: file.name.split(".").pop(),
        file_instance_name: "order_assets"
      };
      getPresignedLink(fileParams, file)
        .then(res => {
          this.failedUnitImg["asset_url"] = res.url.split("?")[0];
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewServiceReport(qid) {
      getServiceReportPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewInvoice(qid) {
      getInvoicePDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  computed: {
    ...mapState("PurchaseOrderSt1ContractModal", {
      isModalOpenedPurchaseOrderSt1: state => state.isModalOpened
    }),
    ...mapState("PurchaseOrderSt2ContractModal", {
      isModalOpenedPurchaseOrderSt2: state => state.isModalOpened,
      termsChecked: state => state.termsChecked,
      quoteId: state => state.quoteId
    }),
    showHideAvailable() {
      const hasQuotDoc = this.qouteRequest.quotation_doc;
      const hasPurcDoc = this.qouteRequest.purchase_order_doc;
      const hasServDoc = this.qouteRequest.service_report_doc;
      return hasQuotDoc || hasPurcDoc || hasServDoc;
    },
    newExpertAvailable() {
      const experts = this.quotationForTicket.experts || [];
      return experts.filter(u => u.pending_sign).length > 0;
    },
    currentQuotationData() {
      const experts = this.quotationForTicket.experts || [];
      const techs = this.quotationForTicket.technicians || [];
      const isExpert = experts.filter(
        u => u.quote_request_id === Number(this.$route.params["qid"])
      );
      const isTechnician = techs.filter(
        u => u.quote_request_id === Number(this.$route.params["qid"])
      );
      return isExpert.length > 0 ? isExpert[0] : isTechnician[0];
    },
    isRequestAdditionalDiagnosticAvailable() {
      // TODO: Amen
      const currentQuotationData = this.currentQuotationData;
      const expertsLen = this.expertsList.length;
      return (
        expertsLen >= 2 &&
        currentQuotationData !== undefined &&
        !currentQuotationData.additional_diagnostic_requested &&
        !this.noMoreAdditionalRequests &&
        !this.confirmReportForm.proceed_repairment &&
        ((!this.isWarrantyCase && this.newExpertAvailable) ||
          (currentQuotationData && !currentQuotationData.repair_only))
      );
    },
    isWarrantyCase() {
      return Boolean(
        this.qouteRequest.warranty_info && this.qouteRequest.warranty_info.original_ticket_id
      );
    },
    canProceedWithRepairment() {
      const experts = this.quotationForTicket.experts || [];
      const techs = this.quotationForTicket.technicians || [];
      const isExpert = experts.filter(u => !u.repair_skipped);
      const isTechnician = techs.filter(u => !u.repair_skipped);
      const reportDocs = experts.filter(u => u.service_report_doc && u.service_report_doc.id);
      return Boolean(isExpert.length + isTechnician.length > 1) && reportDocs.length > 1;
    },
    estimatedCost() {
      if (
        this.qouteRequest.service_report_doc &&
        this.qouteRequest.user_profile_type !== "TechnicianProfile"
      ) {
        return this.qouteRequest.service_report_doc.summary.total;
      } else if (this.qouteRequest.purchase_order_doc) {
        return this.qouteRequest.purchase_order_doc.summary.total;
      } else if (this.qouteRequest.quotation_doc) {
        return this.qouteRequest.quotation_doc.summary.total;
      } else return "0";
    },
    showConfirmReport() {
      let isShow = false;
      const transactionPassed = this.qouteRequest.transaction_passed;
      const hasNoInvoice = !this.invoice || !this.invoice.id || !this.invoiceButtonActive;

      if (this.qouteRequest.service_report_doc) {
        const serviceReportStatus = this.qouteRequest.service_report_doc.approve_status;
        // eslint-disable-next-line
        /* eslint-disable */
        isShow = Boolean(
      hasNoInvoice &&
            !transactionPassed &&
            (
              serviceReportStatus === "waiting_for_approve" || serviceReportStatus === "approved" && !this.isRepairment
            )
        );
      }
      return isShow;
    },
    ticketData() {
      return this.$store.state.ticket || {};
    },
    ticketState() {
      return this.ticketData.state;
    },
    doubledTicketIsExpert() {
      const isExpertProfile = this.qouteRequest.user_profile_type === "ExpertProfile";
      const isSplitTicket = this.ticketData.performers_required === "expert_and_technician";
      return (isSplitTicket && isExpertProfile) || !isSplitTicket;
    },
    isWarrantyButtonAvailable() {
      const inProcess = this.qouteRequest.is_on_temporary_warranty_process;
      const onWarrantyByDate = this.ticketData.is_active_warranty_date;
      return this.isOnWarranty && onWarrantyByDate && !this.isDiagnostic && !inProcess;
    },
    isOnWarranty() {
      return this.ticketState === "on_warranty";
    },
    isReplaceContractor() {
      // TODO: add check if half time is passed
      return ["offered"].includes(this.qouteRequest.state);
    },
    withdrawPoAvailable() {
      const currentQuotationData = this.currentQuotationData
      return currentQuotationData && ["signed"].includes(currentQuotationData.aasm_state) &&
        this.qouteRequest.purchase_order_doc &&
        !this.qouteRequest.service_report_doc;
    },
    isReplaceContractorAvailable() {
      return this.qouteRequest.replace_applicant_available;
    },
    isShowContacts() {
      return this.qouteRequest.state !== "applied";
    },
    isDiagnostic() {
      return this.ticketData.ticket_type === "diagnostic";
    },
    isRepairment() {
      return this.ticketData.ticket_type === "repair";
    }
  },
  mounted() {
    this.setPurchaseOrderData(this.ticketData);
    this.getQouteRequest();
    this.refetchData();
    getInvoice(this.$route.params["qid"])
      .then(r => {
        this.invoice = r.data;
      })
      .catch(e => {
        console.error(e);
        this.invoice = null;
      });
  },
  watch: {
    ticketData: {
      immediate: true,
      handler(newTicketData, oldTicketData) {
        if (newTicketData !== oldTicketData) {
          this.setPurchaseOrderData(newTicketData);
        }
      }
    }
  }
};
</script>

<style>
.v-card.quote-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
}
.expert-name {
  font-size: 18px;
}

.v-btn.v-size--default {
  font-size: 1.2rem !important;
}
</style>
