<template>
  <div class="cm-parts-materials">
    <div class="cm-parts-materials__input-wrapper mr-6">
      <p class="input-label required">{{ $t("Quantity") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Quantity')"
        solo
        :value="labourQuantity"
        @input="
          setPartsMaterialsLabourQuantity($event);
          CheckRequiredFieldsFilledFunc();
        "
        :suffix="unitsOne"
      />
    </div>
    <!-- <div class="cm-parts-materials__small-select-wrapper">
      <p class="input-label required">{{ $t("Units") }}</p>
      <v-select
        solo
        :placeholder="$t('Select unit')"
        :items="['hours', 'pcs']"
        :value="unitsOne"
        @change="setPartsMaterialsUnitsOne"
        readonly
      />
    </div> -->
    <div class="cm-parts-materials__input-wrapper">
      <p class="input-label required">{{ $t("Price") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Price')"
        solo
        :value="hourlyPrice"
        @input="
          setPartsMaterialsHourlyPrice($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <!-- <div class="cm-parts-materials__input-wrapper">
      <div class="cm-parts-materials__unit-value">{{ unitsTwo | startCase }}</div>
    </div> -->
    <div v-if="price" class="cm-parts-materials__price-wrapper">
      <table class="cm-parts-materials__costs-table">
        <tr>
          <td class="cm-parts-materials__costs-table-cell">{{ $t("Costs") }}</td>
          <td class="cm-parts-materials__costs-table-cell">{{ price }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { CheckRequiredFieldsFilled } from "@/components/ContractModals/Components/BaseParts/ModalValidation";

export default {
  props: {
    requiredFieldsFilled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState("QuotationContractModal", ["partsMaterials", "serviceCurrency"]),
    labourQuantity() {
      return this.partsMaterials.labourQuantity;
    },
    unitsOne() {
      return this.partsMaterials.unitsOne;
    },
    hourlyPrice() {
      return this.partsMaterials.hourlyPrice;
    },
    unitsTwo() {
      return this.partsMaterials.unitsTwo;
    },
    price() {
      const quatation = this.$store.state.QuotationContractModal.serviceCurrency;
      const invoice = this.$store.state.InvoiceContractModal.serviceCurrency;
      const currency = quatation !== "" ? quatation : invoice;
      return this.partsMaterials.price ? `${this.partsMaterials.price} ${currency}` : currency;
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setPartsMaterialsLabourQuantity",
      "setPartsMaterialsUnitsOne",
      "setPartsMaterialsHourlyPrice",
      "setPartsMaterialsUnitsTwo"
    ]),

    CheckRequiredFieldsFilledFunc() {
      const values = [this.labourQuantity, this.hourlyPrice];
      const isFilled = CheckRequiredFieldsFilled(values);
      this.$emit("update:required-fields-filled", isFilled);
    }
  }
};
</script>

<style scoped>
.cm-parts-materials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.cm-parts-materials__input-wrapper,
.cm-parts-materials__small-select-wrapper {
  width: 25%;
  max-width: 230px;
}
.cm-parts-materials__price-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.cm-parts-materials__costs-table-cell:last-of-type {
  font-weight: bold;
}
.cm-parts-materials__unit-value {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
