<template>
  <div class="cm-general-form">
    <div class="cm-general-form__input-wrapper">
      <p class="input-label required">{{ $t("q-ty") }}</p>
      <v-text-field
        :placeholder="$t('q-ty')"
        solo
        :rules="[rules.onlyDigits]"
        :value="quantity"
        @input="
          setQuantity($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <div class="cm-general-form__select-wrapper">
      <p class="input-label required">{{ $t("Units") }}</p>
      <v-select
        solo
        :placeholder="$t('Unit')"
        :items="['hours', 'days']"
        :value="textHint"
        @change="
          setTextHint($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { CheckRequiredFieldsFilled } from "@/components/ContractModals/Components/BaseParts/ModalValidation";

export default {
  props: {
    requiredFieldsFilled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: {
        onlyDigits: value => /^\d*$/.test(value) || "Input is invalid"
      }
    };
  },
  computed: {
    ...mapState("QuotationContractModal", ["quantity", "textHint"])
  },
  methods: {
    ...mapMutations("QuotationContractModal", ["setQuantity", "setTextHint"]),
    CheckRequiredFieldsFilledFunc() {
      const values = [this.quantity, this.textHint];
      const isFilled = CheckRequiredFieldsFilled(values);
      this.$emit("update:required-fields-filled", isFilled);
    }
  }
};
</script>

<style scoped>
.cm-general-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cm-general-form__input-wrapper {
  display: block;
  flex-shrink: 0;
  width: 140px;
  margin-right: 20px;
}
.cm-general-form__select-wrapper {
  flex-grow: 1;
}
</style>
