<template>
  <div class="cm-general-form">
    <div class="cm-general-form__input-wrapper">
      <p class="input-label required">{{ $t("Payment terms, net days") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Payment terms, net days')"
        solo
        :value="paymentDelay"
        @input="
          setPaymentDelay($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <div class="cm-general-form__input-wrapper">
      <p class="input-label required">{{ $t("Minimum service amount, hr") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Minimum service amount, hr')"
        solo
        :value="minimumServiceAmount"
        @input="
          setMinimumServiceAmount($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <div class="cm-general-form__input-wrapper cm-general-form__input-wrapper--smaller">
      <p class="input-label required">{{ $t("Estimated service time, days") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Estimated service time, days')"
        solo
        :value="estimatedServiceTime"
        @input="
          setEstimatedServiceTime($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <div class="cm-general-form__select-wrapper pt-4">
      <p class="input-label required">{{ $t("Work schedule") }}</p>
      <v-select
        solo
        :placeholder="$t('From')"
        :items="scheduleItemsList"
        :value="workScheduleFrom"
        @change="
          setWorkScheduleFrom($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <div class="cm-general-form__select-wrapper cm-general-form__select-wrapper--with-margin pt-4">
      <v-select
        solo
        :placeholder="$t('To')"
        :items="scheduleItemsList"
        :value="workScheduleTo"
        @change="
          setWorkScheduleTo($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import { CheckRequiredFieldsFilled } from "@/components/ContractModals/Components/BaseParts/ModalValidation";

export default {
  props: {
    requiredFieldsFilled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scheduleItemsList: (() => {
        const timeArr = [];
        for (let i = 0; i <= 24; i++) {
          timeArr.push(
            moment()
              .hour(i)
              .minutes(0)
              .format("HH:mm")
          );
        }
        return timeArr;
      })()
    };
  },
  computed: {
    ...mapState("QuotationContractModal", [
      "paymentDelay",
      "minimumServiceAmount",
      "estimatedServiceTime",
      "workScheduleFrom",
      "workScheduleTo"
    ])
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setPaymentDelay",
      "setMinimumServiceAmount",
      "setEstimatedServiceTime",
      "setWorkScheduleFrom",
      "setWorkScheduleTo"
    ]),

    CheckRequiredFieldsFilledFunc() {
      const values = [
        this.paymentDelay,
        this.minimumServiceAmount,
        this.workScheduleTo,
        this.workScheduleFrom,
        this.estimatedServiceTime
      ];
      const isFilled = CheckRequiredFieldsFilled(values);
      this.$emit("update:required-fields-filled", isFilled);
    }
  }
};
</script>

<style scoped>
.cm-general-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.cm-general-form__input-wrapper {
  display: block;
  width: calc(50% - 10px);
}
.cm-general-form__input-wrapper--smaller,
.cm-general-form__select-wrapper {
  width: calc(33.3333333333% - 10px);
}
.cm-general-form__select-wrapper--with-margin {
  margin-top: 32px;
}
</style>
