<template>
  <header class="cm-date-header">
    <div class="cm-date-header__number-wrapper">
      <repair-type-ico
        v-if="repairType"
        :repair-type="repairType"
        class="cm-date-header__icon"
      ></repair-type-ico>
      <p class="cm-date-header__number">{{ number }}</p>
    </div>
    <div class="cm-date-header__title">{{ title }}</div>
    <p class="cm-date-header__date">{{ computedDate }}</p>
    <button class="cm-date-header__close-btn" type="button" @click="$emit('closemodal')"></button>
  </header>
</template>

<script>
import moment from "moment";
import RepairTypeIco from "@/components/RepairTypeIco.vue";

export default {
  props: {
    title: {
      type: String
    },
    date: {
      type: String
    },
    step: {
      type: Number
    },
    number: {
      type: String
    },
    repairType: {
      type: String
    }
  },
  components: { RepairTypeIco },
  computed: {
    computedDate() {
      return moment(this.date, "YYYY-MM-DD").format("D MMM, YYYY");
    }
  }
};
</script>

<style scoped>
.cm-date-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 100px 30px 50px;
  background-color: #f2f2f2;
}
.cm-date-header__number-wrapper {
  display: flex;
  align-items: center;
}
.cm-date-header__icon {
  display: block;
  width: 64px;
  height: 64px;
  margin-right: 25px;
}
.cm-date-header__number,
.cm-date-header__date,
.cm-date-header__title {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
}
.cm-date-header__close-btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 80px;
  background-image: url(~@/assets/icons/close_cross.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px 16px;
}
</style>
