<template>
  <div class="cm-selection-form">
    <div class="cm-selection-form__form">
      <div class="cm-selection-form__select-wrapper">
        <slot></slot>
      </div>
      <v-btn
        fab
        depressed
        color="primary"
        class="cm-selection-form__add-btn"
        @click="addAddCondition($event)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <div class="cm-selection-form__input-wrapper">
        <p class="input-label required">{{ $t("Labour / quantity") }}</p>
        <v-text-field
          type="number"
          min="0"
          :placeholder="$t('Labour / quantity')"
          solo
          :value="data.labourQuantity"
          autocomplete="off"
          @input="setLabourQuantity($event)"
        />
      </div>
      <div class="cm-selection-form__small-select-wrapper">
        <p class="input-label required">{{ $t("Units") }}</p>
        <v-select
          solo
          :placeholder="$t('Unit')"
          :items="unitsList"
          :value="data.unitsOne"
          @change="setUnitsOne($event)"
        />
      </div>
      <div class="cm-selection-form__input-wrapper" v-if="isShowRateFields">
        <p class="input-label required">{{ $t("Hourly rate / price") }}</p>
        <v-text-field
          type="number"
          min="0"
          :placeholder="$t('Hourly rate / price')"
          solo
          :value="data.hourlyPrice"
          autocomplete="off"
          @input="setHourlyPrice($event)"
        />
      </div>
      <div class="cm-selection-form__input-wrapper" v-if="isShowRateFields">
        <div class="cm-selection-form__unit-value">{{ data.unitsTwo | startCase }}</div>
      </div>
    </div>
    <ul v-if="isAnyConditions" class="cm-selection-form__list">
      <li
        v-for="(cond, index) in data.selectedConditions"
        :key="cond.id"
        class="cm-selection-form__list-item"
      >
        <span class="cm-selection-form__list-item-text">{{ cond.title }}</span>
        <button
          class="cm-selection-form__list-item-btn"
          @click="delSelectedCondition(index)"
        ></button>
      </li>
    </ul>
    <div v-if="price" class="cm-selection-form__costs-table-wrapper">
      <table class="cm-selection-form__costs-table">
        <tr>
          <td class="cm-selection-form__costs-table-cell">{{ $t("Costs") }}:</td>
          <td class="cm-selection-form__costs-table-cell">{{ price }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { CheckRequiredFieldsFilled } from "@/components/ContractModals/Components/BaseParts/ModalValidation";

export default {
  props: {
    data: {
      type: Object
    },
    isShowRate: {
      type: Boolean,
      default: true
    },
    requiredFieldsFilled: {
      type: Boolean,
      default: true
    },
    unitsList: {
      type: Array,
      default: function() {
        return ["pcs", "hours"];
      }
    }
  },
  computed: {
    isAnyConditions() {
      return this.data.selectedConditions && this.data.selectedConditions.length;
    },
    isShowRateFields() {
      return this.isShowRate;
    },
    price() {
      const quatation = this.$store.state.QuotationContractModal.serviceCurrency;
      const invoice = this.$store.state.InvoiceContractModal.serviceCurrency;
      return quatation !== "" ? quatation : invoice;
    }
  },
  data() {
    return {
      hourlyPrice: null,
      unitsOne: null,
      labourQuantity: null
    };
  },
  methods: {
    addAddCondition() {
      this.$emit("addcondition");
      this.CheckRequiredFieldsFilledFunc();
    },
    delSelectedCondition(index) {
      this.$emit("delcondition", index);
      this.CheckRequiredFieldsFilledFunc();
    },
    setLabourQuantity(value) {
      this.labourQuantity = value;
      this.$emit("setlabourquantity", value);
      this.CheckRequiredFieldsFilledFunc();
    },
    setUnitsOne(value) {
      this.unitsOne = value;
      this.$emit("setunitsone", value);
      this.CheckRequiredFieldsFilledFunc();
    },
    setHourlyPrice(value) {
      this.hourlyPrice = value;
      this.CheckRequiredFieldsFilledFunc();
      this.$emit("sethourlyprice", value);
    },
    setUnitsTwo(value) {
      this.$emit("setunitstwo", value);
      this.CheckRequiredFieldsFilledFunc();
    },
    CheckRequiredFieldsFilledFunc() {
      const values = [
        this.hourlyPrice || this.data.hourlyPrice,
        this.unitsOne || this.data.unitsOne,
        this.labourQuantity || this.data.labourQuantity
      ];
      const isFilled = CheckRequiredFieldsFilled(values);
      this.$emit("update:required-fields-filled", isFilled);
    }
  }
};
</script>

<style scoped>
.cm-selection-form {
  display: block;
  width: 100%;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.cm-selection-form__form {
  display: flex;
  align-items: center;
  width: 100%;
}
.cm-selection-form__select-wrapper {
  width: 290px;
  margin-right: 10px;
}
.cm-selection-form__add-btn {
  margin-right: 20px;
}
.cm-selection-form__input-wrapper {
  width: 180px;
  margin-right: 10px;
}
.cm-selection-form__small-select-wrapper {
  width: 150px;
  margin-right: 25px;
}
.cm-selection-form__small-select-wrapper:last-child {
  margin-right: 0;
}
.cm-selection-form__list {
  display: block;
  width: 100%;
  padding: 0 10px;
}
.cm-selection-form__list-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 320px;
  padding: 13px 33px 13px 17px;
}
.cm-selection-form__list-item-text {
  font-size: 20px;
  font-weight: 500;
}
.cm-selection-form__list-item-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: none;
  width: 33px;
  background-image: url(~@/assets/icons/close_cross.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 16px 16px;
}
.cm-selection-form__list-item:hover {
  background-color: #c9ebff;
}
.cm-selection-form__list-item:hover .cm-selection-form__list-item-btn {
  display: block;
}
.cm-selection-form__costs-table-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.cm-selection-form__costs-table {
}
.cm-selection-form__costs-table-cell:last-of-type {
  font-weight: bold;
}
.cm-selection-form__unit-value {
  margin-left: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
