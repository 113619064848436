<template>
  <div class="cm-summary">
    <table class="cm-summary__table">
      <tr v-for="row in rows" :key="row.title">
        <td class="cm-summary__table-cell">{{ row.title }}</td>
        <td v-if="row.finalPrice" class="cm-summary__table-cell py-4">{{ row.value }}</td>
        <td v-else class="cm-summary__table-cell">
          <v-text-field
            solo
            hide-details
            small
            type="number"
            min="0"
            :disabled="row.disabled"
            :value="row.value"
            class="cm-summary__input"
            @input="row.setAction"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import { split } from "lodash/string";

export default {
  computed: {
    ...mapState("QuotationContractModal", [
      "prices",
      "minimumServiceAmount",
      "estimatedServiceTime",
      "requestedServices",
      "partsMaterials",
      "limitedWarranty",
      "handlingShipping",
      "workScheduleFrom",
      "workScheduleTo",
      "quoteId",
      "serviceCurrency"
    ]),
    rows() {
      const quatation = this.$store.state.QuotationContractModal.serviceCurrency;
      const invoice = this.$store.state.InvoiceContractModal.serviceCurrency;
      const currency = quatation !== "" ? quatation : invoice;

      return [
        {
          title: this.$t("Subtotal"),
          disabled: true,
          value: 0,
          setAction: this.setSubtotal
        },
        { title: this.$t("VAT"), disabled: true, value: 0, setAction: this.setVat },
        {
          title: this.$t("Total"),
          disabled: true,
          value: `0 ${currency}`,
          finalPrice: true
        },
        {
          title: this.$t("Platform fee"),
          disabled: true,
          value: `0 ${currency}`,
          finalPrice: true
        },
        {
          title: this.$t("Sum to be paid"),
          disabled: false,
          value: `0 ${currency}`,
          finalPrice: true
        }
      ];
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setSubtotal",
      "setVat",
      "setTotal",
      "setFixedFee",
      "setPlatformFee"
    ])
  }
};
</script>

<style scoped>
.cm-summary {
  display: block;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
}
.cm-summary__table {
  width: 100%;
  max-width: 450px;
}
.cm-summary__table-cell {
  padding: 5px;
}
.cm-summary__table-cell:last-of-type {
  font-weight: bold;
}
.cm-summary__input {
  width: 150px;
}
</style>
