<template>
  <table class="data-table">
    <tr v-for="row in data" :key="row.label">
      <td class="data-table__cell">{{ row.label }}</td>
      <td class="data-table__cell">{{ row.value }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: Array
    }
  }
};
</script>

<style scoped>
.data-table {
  width: 100%;
  font-size: 20px;
}
.data-table__cell {
  padding: 5px 0;
}
.data-table__cell:first-of-type {
  width: 120px;
  color: #999999;
}
</style>
