<template>
  <contract-modal
    :show-modal="showModal"
    :btn-text="btnText"
    @closemodal="closeModal"
    @formsubmitted="$emit('formsubmitted')"
  >
    <template v-slot:header>
      <date-header
        title="Fill up and Send Contract"
        :date="date"
        :number="headerNbrNew"
        :repair-type="repairType"
        @closemodal="closeModal"
      />
    </template>
    <div class="cm-modal__info-wrapper">
      <p class="cm-modal__info-text">{{ manufacturerInfo }}</p>
      <header-steps :step="1" />
    </div>
    <div class="cm-modal__mixed-section">
      <cm-section :title="$t('Repair type')" class="cm-modal__small-cm-section">
        <options-choice :options="repairTypeOptions" />
      </cm-section>
      <cm-section :title="$t('Interaction language')" class="cm-modal__small-cm-section">
        <options-choice :options="interactionLangsOptions" />
      </cm-section>
    </div>
    <cm-section :title="$t('Object conditions')" class="cm-modal__half-width-section">
      <tiles-section :tiles="conditionTiles" />
    </cm-section>
    <cm-section :title="$t('Additional conditions')" class="cm-modal__half-width-section">
      <tiles-section :tiles="addConditions" />
    </cm-section>
    <cm-section v-for="(section, index) in sections" :title="section.title" :key="index">
      <component :is="section.component" :data="section.data"></component>
    </cm-section>
  </contract-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ContractModal from "@/components/ContractModals/ContractModalSkeleton";
import DateHeader from "@/components/ContractModals/Components/DateHeader";
import ModalSection from "@/components/ContractModals/Components/ModalSection";
// PARTS
import HeaderSteps from "@/components/ContractModals/Components/HeaderSteps";
import OptionsChoice from "@/components/ContractModals/Components/PurchaseOrderSt1Parts/OptionsChoice";
import TilesSection from "@/components/ContractModals/Components/PurchaseOrderSt1Parts/TilesSection";
import DataTable from "@/components/ContractModals/Components/PurchaseOrderSt1Parts/DataTable";

export default {
  components: {
    "header-steps": HeaderSteps,
    "contract-modal": ContractModal,
    "date-header": DateHeader,
    "cm-section": ModalSection,
    "options-choice": OptionsChoice,
    "tiles-section": TilesSection,
    "data-table": DataTable
  },
  props: {
    data: {
      type: Object
    },
    showModal: {
      type: Boolean
    },
    btnText: {
      type: String
    }
  },
  computed: {
    ...mapState("PurchaseOrderSt1ContractModal", [
      "headerNbr",
      "repairType",
      "manufacturerInfo",
      "addConditions",
      "workConditions",
      "interactionLangs"
    ]),
    quotationData() {
      return this.data || {};
    },
    quotationDoc() {
      return this.quotationData.quotation_doc || {};
    },
    date() {
      return this.quotationDoc.date;
    },
    headerNbrNew() {
      if (this.quotationDoc && this.quotationDoc.number) {
        return this.quotationDoc.number.replace("Rq", "Po");
      } else {
        return this.headerNbr;
      }
    },
    addConditionsList() {
      return this.$store.state.additionalConditions || [];
      // return this.$store.state.servicesList || [];
    },
    addresses() {
      return this.quotationDoc.addresses || {};
    },
    techLegalAddress() {
      return this.addresses.performer_address || {};
    },
    clientLegalAddress() {
      return this.addresses.customer_address || {};
    },
    facilityLegalAddress() {
      return this.addresses.order_address || {};
    },
    repairTypeOptions() {
      switch (this.repairType) {
        case "expertise":
          return [this.$t("Expertise")];
        case "machine_repair":
          return [this.$t("Machine repair")];
        case "assembly_repair":
          return [this.$t("Assembly repair")];
        default:
          return null;
      }
    },
    interactionLangsOptions() {
      return this.interactionLangs && this.interactionLangs.length
        ? this.interactionLangs.map(item => item[1])
        : null;
    },
    addConditionTiles() {
      return this.addConditions;
    },
    conditionTiles() {
      const workConditions = Array.isArray(this.workConditions)
        ? this.workConditions
        : [this.workConditions];
      const selectedConditionTiles = [];
      workConditions.forEach(item => {
        selectedConditionTiles.push({
          id: item,
          title: item,
          slug: item
        });
      });
      return selectedConditionTiles;
    },
    sections() {
      return [
        {
          title: this.$t("Technician’s legal address"),
          component: "data-table",
          data: this.techAddress
        },
        {
          title: this.$t("Client’s legal address"),
          component: "data-table",
          data: this.clientAddress
        },
        {
          title: this.$t("Facility legal address"),
          component: "data-table",
          data: this.facilityAddress
        }
      ];
    },
    techAddress() {
      return [
        { label: this.$t("Country"), value: this.techLegalAddress.country },
        { label: this.$t("State"), value: this.techLegalAddress.state },
        { label: this.$t("City"), value: this.techLegalAddress.city },
        { label: this.$t("Street"), value: this.techLegalAddress.street },
        { label: this.$t("Zip"), value: this.techLegalAddress.zip },
        { label: this.$t("Phone"), value: this.techLegalAddress.phone },
        { label: this.$t("Skype"), value: this.techLegalAddress.skype },
        { label: this.$t("Email"), value: this.techLegalAddress.email }
      ];
    },
    clientAddress() {
      return [
        { label: this.$t("Country"), value: this.clientLegalAddress.country },
        { label: this.$t("State"), value: this.clientLegalAddress.state },
        { label: this.$t("City"), value: this.clientLegalAddress.city },
        { label: this.$t("Street"), value: this.clientLegalAddress.street },
        { label: this.$t("Zip"), value: this.clientLegalAddress.zip },
        { label: this.$t("Phone"), value: this.clientLegalAddress.phone },
        { label: this.$t("Skype"), value: this.clientLegalAddress.skype },
        { label: this.$t("Email"), value: this.clientLegalAddress.email }
      ];
    },
    facilityAddress() {
      return [
        { label: this.$t("Country"), value: this.facilityLegalAddress.country },
        { label: this.$t("State"), value: this.facilityLegalAddress.state },
        { label: this.$t("City"), value: this.facilityLegalAddress.city },
        { label: this.$t("Street"), value: this.facilityLegalAddress.street },
        { label: this.$t("Zip"), value: this.facilityLegalAddress.zip },
        { label: this.$t("Phone"), value: this.facilityLegalAddress.phone },
        { label: this.$t("Skype"), value: this.facilityLegalAddress.skype },
        { label: this.$t("Email"), value: this.facilityLegalAddress.email }
      ];
    }
  },
  methods: {
    ...mapMutations("PurchaseOrderSt1ContractModal", ["closeModal"])
  }
};
</script>

<style scoped>
.cm-modal__info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 45px;
}
.cm-modal__info-text {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}
.cm-modal__half-width-section {
  width: calc(50% - 20px);
}
.cm-modal__mixed-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.cm-modal__small-cm-section {
  width: 280px;
  max-width: calc(33.3333333% - 10px);
  margin-right: 25px;
}
.cm-modal__input-cont {
  width: 350px;
  max-width: calc(33.3333333% - 10px);
}
</style>
