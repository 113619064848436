<template>
  <div class="cm-limited-warranty">
    <div class="cm-limited-warranty__input-wrapper">
      <p class="input-label required">{{ $t("Paid Warranty Days") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Paid Warranty Days')"
        :rules="[rules.onlyDigits]"
        solo
        :value="labourQuantity"
        @input="
          setLimitedWarrantyLabourQuantity($event);
          CheckRequiredFieldsFilledFunc();
        "
        suffix="Pcs"
      />
    </div>
    <!-- <div class="cm-limited-warranty__small-select-wrapper">
      <p class="input-label required">{{ $t("Units") }}</p>
      <div class="cm-limited-warranty__input-wrapper">
        <div class="cm-limited-warranty__unit-value">{{ unitsTwo | startCase }}</div>
      </div>
    </div> -->
    <div class="cm-limited-warranty__input-wrapper">
      <p class="input-label required">{{ $t("Price") }}</p>
      <v-text-field
        type="number"
        min="0"
        :placeholder="$t('Price')"
        solo
        :value="hourlyPrice"
        @input="
          setLimitedWarrantyHourlyPrice($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
    <!-- <div class="cm-limited-warranty__input-wrapper">
      <div class="cm-limited-warranty__unit-value">{{ unitsTwo | startCase }}</div>
    </div> -->
    <div class="cm-limited-warranty__input-wrapper">
      <p class="input-label required">{{ $t("Warranty term") }}</p>
      <v-text-field
        :placeholder="$t('Warranty term')"
        solo
        :value="warrantyTerm"
        :rules="[rules.onlyDigits]"
        @input="
          setLimitedWarrantyTerm($event);
          CheckRequiredFieldsFilledFunc();
        "
        suffix="days"
      />
    </div>
    <div v-if="price" class="cm-limited-warranty__price-wrapper">
      <table class="cm-limited-warranty__costs-table">
        <tr>
          <td class="cm-limited-warranty__costs-table-cell">{{ $t("Costs") }}:</td>
          <td class="cm-limited-warranty__costs-table-cell">{{ price }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { CheckRequiredFieldsFilled } from "@/components/ContractModals/Components/BaseParts/ModalValidation";

export default {
  props: {
    requiredFieldsFilled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: {
        onlyDigits: value => /^\d*$/.test(value) || "Input is invalid"
      }
    };
  },
  computed: {
    ...mapState("QuotationContractModal", ["limitedWarranty", "ticketType", "serviceCurrency"]),
    labourQuantity() {
      return this.limitedWarranty.labourQuantity;
    },
    unitsOne() {
      return this.limitedWarranty.unitsOne;
    },
    hourlyPrice() {
      return this.limitedWarranty.hourlyPrice;
    },
    unitsTwo() {
      return this.limitedWarranty.unitsTwo;
    },
    warrantyTerm() {
      return this.limitedWarranty.warrantyTerm;
    },
    price() {
      const quatation = this.$store.state.QuotationContractModal.serviceCurrency;
      const invoice = this.$store.state.InvoiceContractModal.serviceCurrency;
      const currency = quatation !== "" ? quatation : invoice;
      return this.limitedWarranty.price ? `${this.limitedWarranty.price} ${currency}` : currency;
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setLimitedWarrantyLabourQuantity",
      "setLimitedWarrantyUnitsOne",
      "setLimitedWarrantyHourlyPrice",
      "setLimitedWarrantyUnitsTwo",
      "setLimitedWarrantyTerm"
    ]),
    CheckRequiredFieldsFilledFunc() {
      const values = [this.labourQuantity, this.hourlyPrice, this.warrantyTerm];
      const isFilled = CheckRequiredFieldsFilled(values);
      this.$emit("update:required-fields-filled", isFilled);
    }
  }
};
</script>

<style scoped>
.cm-limited-warranty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.cm-limited-warranty__input-wrapper,
.cm-limited-warranty__small-select-wrapper {
  width: 25%;
  max-width: 230px;
}
.cm-limited-warranty__price-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.cm-limited-warranty__costs-table-cell:last-of-type {
  font-weight: bold;
}
.cm-limited-warranty__unit-value {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
