<template>
  <v-row class="px-4" v-if="isShow">
    <v-col>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="qouteRequest.quotation_doc"
        @click="viewQuotation(qouteRequest.quotation_doc.id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Quotation") }}</span>
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="qouteRequest.purchase_order_doc"
        @click="viewPurchaseOrder(qouteRequest.id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Purchase Order") }}</span>
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="qouteRequest.service_report_doc && !showPackingSlipReport"
        @click="viewServiceReport(qouteRequest.id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Service report") }}</span>
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="qouteRequest.service_report_doc && showPackingSlipReport"
        @click="viewServiceReport(qouteRequest.id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Packing slip") }}</span>
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        v-if="qouteRequest.service_report_doc && qouteRequest.state === 'done'"
        :disabled="!invoice || !invoice.id"
        @click="viewInvoice(qouteRequest.id)"
      >
        <v-icon left>mdi-file-document-outline</v-icon>
        <span class="text-left">{{ $t("Invoice") }}</span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {
  getQuotationPDF,
  getQuoteRequestPDF,
  getPurchaseOrderPDF,
  getServiceReportPDF,
  getInvoicePDF,
  getInvoice
} from "@/api";

export default {
  name: "pdf-documents",
  props: ["qouteRequest", "isShow"],
  data: () => ({
    invoice: null
  }),
  computed: {
    showPackingSlipReport() {
      return this.qouteRequest.subject_description.repair_type === "assembly_repair";
    }
  },
  methods: {
    viewQuotationRequest(qid) {
      getQuoteRequestPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewQuotation(qid) {
      getQuotationPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewPurchaseOrder(qid) {
      getPurchaseOrderPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewServiceReport(qid) {
      getServiceReportPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewInvoice(qid) {
      getInvoicePDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    }
  },
  mounted() {
    const invoiceId = Object.hasOwnProperty.call(this.$route.params, "qid")
      ? this.$route.params["qid"]
      : this.$route.params["id"];

    getInvoice(invoiceId)
      .then(r => {
        this.invoice = r.data;
      })
      .catch(e => {
        console.error(e);
        this.invoice = null;
      });
  }
};
</script>

<style></style>
