<template>
  <section class="cm-section" v-if="!disabled">
    <h3 class="cm-section__title">{{ title }}</h3>
    <div class="cm-section__content">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.cm-section {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.cm-section__title {
  display: block;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
}
.cm-section__content {
  display: block;
  width: 100%;
  padding: 35px 0;
}
</style>
