<template>
  <div class="cm-option-choice">
    <template v-if="options && options.length">
      <span v-for="option in options" :key="option" class="cm-option-choice__text">
        {{ option }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array
    }
  }
};
</script>

<style scoped>
.cm-option-choice {
  display: block;
  width: 100%;
}
.cm-option-choice__text {
  display: inline-block;
  width: auto;
  margin-right: 5px;
  padding: 3px 15px;
  font-size: 14px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
</style>
