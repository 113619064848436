<template>
  <contract-modal
    :show-modal="showModal"
    :btn-text="btnText"
    @closemodal="closeModal"
    @formsubmitted="$emit('formsubmitted')"
    v-bind:disableNext.sync="nextButtonDisabled"
  >
    <template v-slot:header>
      <date-header
        :title="modalTitle"
        :date="date"
        :number="headerNbrNew"
        :repair-type="repairType"
        @closemodal="closeModal"
      />
    </template>
    <div class="cm-modal__info-wrapper">
      <p class="cm-modal__info-text">{{ manufacturerInfo }}</p>
      <header-steps :step="2" />
    </div>
    <cm-section
      v-for="(section, index) in activeSectionComponents()"
      :title="section.title"
      :key="index"
      :class="section.class"
      :disabled="skipBlock(section.component)"
    >
      <section v-if="section.syncRequiredFilled">
        <component
          :is="section.component"
          v-if="!skipBlock(section.component)"
          v-bind:required-fields-filled.sync="allFieldFilled[section.component]"
          v-on:update:required-fields-filled="nextButtonDisabledCheck()"
        ></component>
      </section>
      <section v-else>
        <component v-if="!skipBlock(section.component)" :is="section.component"></component>
      </section>
    </cm-section>
    <template v-slot:terms>
      <v-checkbox
        :label="$t('By clicking “Sign” I agree to the Purchase Order above.')"
        v-model="termsCheckedInner"
        class="cm-modal__terms-checkbox"
      />
    </template>
  </contract-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ContractModal from "@/components/ContractModals/ContractModalSkeleton";
import DateHeader from "@/components/ContractModals/Components/DateHeader";
import ModalSection from "@/components/ContractModals/Components/ModalSection";
// PARTS
import HeaderSteps from "@/components/ContractModals/Components/HeaderSteps";
import GeneralForm from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/GeneralForm";
import UrgencyStartForm from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/UrgencyStartForm";
import SelectionForm from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/SelectionForm";
import PartsMaterials from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/PartsMaterials";
import LimitedWarranty from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/LimitedWarranty";
import HandlingShipping from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/HandlingShipping";
import Summary from "@/components/ContractModals/Components/PurchaseOrderSt2Parts/Summary";
import SummaryWarrantyRepair from "@/components/ContractModals/Components/QuotationParts/SummaryWarrantyRepair";

export default {
  components: {
    "header-steps": HeaderSteps,
    "contract-modal": ContractModal,
    "date-header": DateHeader,
    "cm-section": ModalSection,
    "general-form": GeneralForm,
    "urgency-start-form": UrgencyStartForm,
    "selection-form": SelectionForm,
    "parts-materials-form": PartsMaterials,
    "limited-warranty-form": LimitedWarranty,
    "hadnling-shipping-form": HandlingShipping,
    "cm-summary": Summary,
    "cm-summary-warranty-repair": SummaryWarrantyRepair
  },
  props: {
    data: {
      type: Object
    },
    showModal: {
      type: Boolean
    },
    btnText: {
      type: String
    }
  },
  data() {
    return {
      nextButtonDisabled: false,
      allFieldFilled: {
        "general-form": true,
        "urgency-start-form": true,
        "selection-form": true,
        "parts-materials-form": true,
        "limited-warranty-form": true,
        "hadnling-shipping-form": true
      },
      sections: [
        {
          title: this.$t("General"),
          component: "general-form",
          class: "cm-modal__general-section",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Urgency-Start within"),
          component: "urgency-start-form",
          class: "cm-modal__urgency-section",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Requested service"),
          component: "selection-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Parts and materials"),
          component: "parts-materials-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Limited warranty"),
          component: "limited-warranty-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        {
          title: this.$t("Handling and shipping"),
          component: "hadnling-shipping-form",
          syncRequiredFilled: "requiredFieldsFilled"
        },
        { title: this.$t("Summary"), component: "cm-summary" }
      ]
    };
  },
  computed: {
    ...mapState("PurchaseOrderSt1ContractModal", ["headerNbr", "repairType", "manufacturerInfo"]),
    ...mapState("PurchaseOrderSt2ContractModal", ["termsChecked"]),
    ...mapState("InvoiceContractModal", ["ticketType"]),
    headerNbrNew() {
      if (this.quotationDoc && this.quotationDoc.number) {
        return this.quotationDoc.number.replace("Rq", "Po");
      } else {
        return this.headerNbr;
      }
    },
    modalTitle() {
      let title = this.$t("Fill up and Send Contract");
      if (this.isWarrantyRepair) {
        title = this.$t("Warranty Repairment Process");
      }
      return title;
    },
    isDiagnostic() {
      return this.ticketType === "diagnostic";
    },
    isRepair() {
      return !this.isDiagnostic;
    },
    quotationData() {
      return this.data || {};
    },
    quotationDoc() {
      return this.quotationData.quotation_doc || {};
    },
    isWarranty() {
      return Boolean(
        this.quotationData.warranty_info && this.quotationData.warranty_info.original_ticket_id
      );
    },
    isWarrantyRepair() {
      return this.isWarranty && this.isRepair;
    },
    date() {
      return this.quotationDoc.date;
    },
    termsCheckedInner: {
      get() {
        return this.termsChecked;
      },
      set(val) {
        this.setTerms(val);
      }
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.setTicketType(null);
        this.setPurchaseOrderData(this.quotationDoc);
      } else {
        this.clearModalData();
      }
    }
  },
  methods: {
    ...mapMutations("PurchaseOrderSt2ContractModal", [
      "setTerms",
      "closeModal",
      "setPurchaseOrderData",
      "setTicketType",
      "clearModalData"
    ]),
    activeSectionComponents() {
      const componentsData = this.sections;

      return componentsData;
    },

    nextButtonDisabledCheck() {
      let enable = true;
      const keys = Object.keys(this.allFieldFilled);
      for (const name in keys) {
        const key = keys[name];
        enable = this.allFieldFilled[key];

        if (!enable) {
          break;
        }
      }

      this.nextButtonDisabled = !enable;
    },
    skipBlock(component) {
      return this.isDiagnostic && component === "limited-warranty-form";
    }
  }
};
</script>

<style scoped>
.cm-modal__info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 45px;
}
.cm-modal__info-text {
  display: inline-block;
  width: auto;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}
.cm-modal__half-width-section {
  width: calc(50% - 20px);
}
.cm-modal__general-section {
  width: calc(55% - 20px);
}
.cm-modal__urgency-section {
  width: calc(45% - 20px);
}
.cm-modal__terms-checkbox {
  margin-top: 0;
  margin-right: 25px;
}
</style>
