export function CheckRequiredFieldsFilled(values) {
  let isFilled = true;

  for (const index in values) {
    const value = values[index];
    if (value === null || value === undefined || value.length < 1) {
      isFilled = false;
      break;
    }
  }

  return isFilled;
}
