<template>
  <v-card class="ticket-chat">
    <v-card-title>
      <v-list-item-avatar width="50" height="50" class="performer-avatar" color="secondary">
        <v-icon v-if="!performerAvatar" x-large color="white" class="performer-icon">
          mdi-account-outline</v-icon
        >
        <img v-if="performerAvatar" :src="performerAvatar" />
      </v-list-item-avatar>
      <span class="headline">{{ performerName }}</span>
    </v-card-title>

    <v-card-text class="messages-list-wrapper" ref="messagesCont">
      <div
        v-for="dateMessage in dateMessagesGroup"
        :key="dateMessage.date"
        class="messages-date-group"
      >
        <p class="messages-date">{{ getMessageDate(dateMessage.date) }}</p>
        <div
          v-for="message in dateMessage.items"
          :key="message.id"
          :class="{ 'is-sender': !isSender(message.user_id) }"
          class="message"
        >
          <div class="message-wrapper" :title="message.read ? $t('Read') : $t('Not Read')">
            <div class="message-time">{{ getMessageTime(message.created_at) }}</div>
            <div class="message-content">
              <p class="message-text">{{ message.body }}</p>
              <img
                v-if="
                  hasAttachments(message.attachments) && isImageAttachment(message.attachments[0])
                "
                :src="message.attachments[0]"
                :alt="getFileName(message.attachments[0])"
                class="message-attached-img"
              />
              <a
                v-if="hasAttachments(message.attachments)"
                :href="message.attachments[0]"
                class="message-attachment"
              >
                <v-icon left>mdi-cloud-download</v-icon>
                {{ getFileName(message.attachments[0]) }}
              </a>
            </div>
            <div class="message-read-status" v-if="isSender(message.user_id)">
              <v-icon small light v-if="message.read">mdi-check-all</v-icon>
              <v-icon small light v-else>mdi-check</v-icon>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions class="ticket-chat__controls">
      <v-file-input
        :disabled="isMessageInProgress"
        hide-input
        clearable
        accept="image/*"
        class="ticket-chat__add-file-btn"
        @change="setFile"
      ></v-file-input>
      <v-text-field
        v-model="message"
        :append-outer-icon="'mdi-send'"
        :disabled="isMessageInProgress"
        :hide-details="'auto'"
        :error-messages="errors"
        :label="messagePlaceholderText"
        clear-icon="mdi-close-circle"
        filled
        clearable
        solo
        type="text"
        class="ticket-chat__message-input"
        @click:append-outer="catchMessageData"
        @click:clear="clearMessage"
        @keydown.enter="catchMessageData"
      ></v-text-field>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { getUserInfo } from "@/auth/auth";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ticket-chat-dialog",
  props: {
    conversationId: Number,
    performerName: String,
    performerAvatar: String
  },
  data() {
    return {
      file: "",
      message: "",
      userInfo: getUserInfo() || {},
      isMessageInProgress: false,
      errors: []
    };
  },
  computed: {
    ...mapState("ChatStore", ["conversation"]),
    messagePlaceholderText() {
      return this.$t("Message");
    },
    dateMessagesGroup() {
      return this.conversation || [];
    },
    currentUserId() {
      return this.userInfo.id || "";
    }
  },
  watch: {
    conversation() {
      if (this.conversation.length) {
        this.scrollToLastMessage();
      }
    }
  },
  methods: {
    ...mapActions("ChatStore", ["sendMessage", "sendFile", "createForm"]),
    ...mapMutations("ChatStore", ["setFile", "setMessage"]),
    getMessageDate(createdDate) {
      return moment(createdDate).format("DD/MM/YYYY");
    },
    getMessageTime(createdTime) {
      return moment(createdTime).format("HH:mm ");
    },
    getFileName(fileLink) {
      const splittedLink = fileLink.split("/");
      return splittedLink[splittedLink.length - 1];
    },
    isImageAttachment(fileLink) {
      const splittedLink = fileLink.split(".");
      const attachmentExtension = splittedLink[splittedLink.length - 1];
      const allowedImageFormats = ["jpg", "jpeg", "png"];
      return allowedImageFormats.includes(attachmentExtension);
    },
    hasAttachments(attachments) {
      return attachments && attachments.length;
    },
    isSender(userId) {
      return userId === this.currentUserId;
    },
    setFile(value) {
      this.file = value;
    },
    clearMessage() {
      this.file = "";
      this.message = "";
    },
    catchMessageData() {
      if (this.isValidMessage()) {
        this.sendMessageData();
      }
    },
    isValidMessage() {
      this.errors = [];
      if (this.file && this.file.size > 52428800) {
        this.errors.push(this.$t("Max file size is 500 mb"));
      }
      if (this.file && !this.file.type.includes("image")) {
        this.errors.push(this.$t("Only images are available"));
      }
      if (!this.message) {
        this.errors.push(this.$t("Message is required"));
      }
      return !this.errors.length;
    },
    sendMessageData() {
      this.isMessageInProgress = true;
      this.sendFile(this.file)
        .then(file => {
          return this.createForm({
            message: this.message,
            file
          });
        })
        .then(form => {
          return this.sendMessage({
            conversationId: this.conversationId,
            form
          });
        })
        .then(() => {
          this.isMessageInProgress = false;
          this.clearMessage();
        })
        .catch(e => {
          this.isMessageInProgress = false;
          console.error(e);
        });
    },
    scrollToLastMessage() {
      const messageCont = this.$refs.messagesCont;
      if (messageCont) {
        this.$nextTick(() => {
          messageCont.scrollTop = messageCont.scrollHeight;
        });
      }
    }
  }
};
</script>

<style>
.ticket-chat__controls .v-text-field.v-text-field--solo .v-input__append-outer {
  margin-top: 17px;
}
.ticket-chat__controls.v-card__actions {
  align-items: flex-start;
}
</style>

<style lang="scss" scoped>
.performer-avatar {
  margin: 0 18px 0 0;
  border-radius: 8px;
  color: #fff;
  .performer-icon {
    font-size: 30px;
  }
}
.ticket-chat {
  width: 100%;
  .messages-list-wrapper {
    .messages-date {
      margin-bottom: 0;
      text-align: center;
      font-weight: bold;
    }
    .message {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      &.is-sender {
        justify-content: flex-start;
        text-align: left;
        .message-content {
          background: #5f5f5f;
          color: #fff;
        }
      }
    }
    .message-wrapper {
      flex-shrink: 0;
      display: inline-block;
      max-width: 400px;
      margin-bottom: 10px;
      position: relative;

      .message-read-status {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 25px;
        text-align: left;
      }

      .message-time {
        padding: 0 10px 10px;
        color: #aeaeae;
      }
      .message-content {
        position: relative;
        padding: 22px;
        background-color: #c4e8ff;
        font-size: 20px;
        border-radius: 8px;

        .message-text {
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
        }
        .message-attached-img {
          max-width: 100%;
        }
        .message-attachment {
          color: rgba(0, 0, 0, 0.7);
          text-decoration: none;
          font-size: 14px;
          font-weight: bold;
          &:hover {
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
  }
  .ticket-chat__controls {
    .ticket-chat__add-file-btn {
      flex-shrink: 0;
      margin: 0;
    }
    .ticket-chat__message-input {
      width: 100%;
    }
  }
}

.ticket-chat__add-file-btn .v-input__control {
  display: none !important;
}
</style>
