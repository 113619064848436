<template>
  <v-dialog v-model="showChat" scrollable max-width="750" v-if="conversationId !== 0">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text small color="primary" class="ticket-open-chat">
        <v-icon left>mdi-message-text</v-icon>
        <span class="text-left">{{ $t("Open chat") }}</span>
      </v-btn>
    </template>
    <ticket-chat
      v-if="showChat"
      :conversation-id="conversationId"
      :performer-name="performerName"
      :performer-avatar="performerAvatar"
    ></ticket-chat>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import TicketChat from "@/components/TicketChat/TicketChat";
import { isNull } from "lodash/lang";

export default {
  name: "ticket-chat-dialog",
  props: {
    contractor: Object,
    is_bid: Boolean
  },
  components: { TicketChat },
  data: () => {
    return {
      showChat: false,
      timerChat: null
    };
  },
  actions: {
    updateConversationData() {
      this.getConversationData(this.conversationId);
    }
  },
  computed: {
    contractorData() {
      return this.contractor || {};
    },
    conversationId() {
      let conversationID = this.contractorData.conversation_id || 0;
      if (
        Object.hasOwnProperty.call(this.contractorData, "quotation_attributes") &&
        !isNull(this.contractorData.quotation_attributes)
      ) {
        conversationID = this.contractorData.quotation_attributes.conversation_id;
      }

      return conversationID;
    },
    performerInfo() {
      if (!this.is_bid) {
        return this.contractorData.performer_info || {};
      }

      return {
        name: this.contractorData.client_name || "",
        avatar_url: this.contractorData.client_avatar || ""
      };
    },
    performerName() {
      return this.performerInfo.name || "";
    },
    performerAvatar() {
      return this.contractorData.avatar_url || this.performerInfo.avatar_url;
    }
  },
  watch: {
    showChat: {
      handler: function() {
        if (this.showChat && this.conversationId) {
          this.getConversationData(this.conversationId);
          this.timerChat = setInterval(
            function() {
              this.getConversationData(this.conversationId);
            }.bind(this),
            3500
          );
        } else {
          this.clearConversation();
          clearInterval(this.timerChat);
        }
      },
      deep: true
    }
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const timer = setInterval(function() {
      if (self.conversationId > 0) {
        clearInterval(timer);
        if (location.hash === "#chat") {
          self.showChat = true;
          location.hash = "#";
        }
      }
    }, 200);
  },
  methods: {
    ...mapActions("ChatStore", ["getConversationData"]),
    ...mapMutations("ChatStore", ["clearConversation"])
  }
};
</script>
