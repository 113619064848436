<template>
  <div class="cm-tiles-section">
    <div
      v-for="tile in tiles"
      :key="tile.id"
      :class="`cm-tiles-section__tile--${tile.id}`"
      class="cm-tiles-section__tile"
    >
      <v-img
        :title="tile.id"
        v-if="tile && tile.slug"
        :src="require(`@/assets/additional_conditions/${tile.slug}.svg`)"
        position="center"
        class="cm-tiles-section__tile-icon my-2 mx-auto align-center"
        width="40px"
        contain
      />
      <p class="cm-tiles-section__tile-text">{{ tile.title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tiles: {
      type: Array
    }
  }
};
</script>

<style scoped>
.cm-tiles-section {
  display: flex;
  width: 100%;
}
.cm-tiles-section__tile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  padding: 12px;
  font-size: 12px;
  text-align: center;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.cm-tiles-section__tile:not(:last-of-type) {
  margin-right: 20px;
}
.cm-tiles-section__tile-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.cm-tiles-section__tile-text {
  margin-bottom: 0;
}
</style>
