<template>
  <div class="cm-summary">
    <table class="cm-summary__table">
      <tr v-for="row in rows" :key="row.title">
        <td class="cm-summary__table-cell">{{ row.title }}</td>
        <td v-if="row.finalPrice" class="cm-summary__table-cell py-4">{{ row.value }}</td>
        <td v-else class="cm-summary__table-cell">
          <v-text-field
            solo
            hide-details
            small
            type="number"
            min="0"
            :disabled="row.disabled"
            :value="row.value"
            class="cm-summary__input"
            @input="row.setAction"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import { split } from "lodash/string";

export default {
  computed: {
    ...mapState("QuotationContractModal", [
      "prices",
      "minimumServiceAmount",
      "estimatedServiceTime",
      "requestedServices",
      "partsMaterials",
      "limitedWarranty",
      "handlingShipping",
      "workScheduleFrom",
      "workScheduleTo",
      "quoteId",
      "serviceCurrency"
    ]),
    subtotalPrice() {
      // general
      // const workFromHour = +split(this.workScheduleFrom, ":", 1)[0];
      // const workToHour = +split(this.workScheduleTo, ":", 1)[0];
      // const hoursPerDay = workToHour - workFromHour;
      // const general = +this.minimumServiceAmount * (+this.estimatedServiceTime * hoursPerDay);
      // Labour
      const storedInvoice = this.$store.state.InvoiceContractModal;
      const isInvoice =
        !this.requestedServices.hourlyPrice.length &&
        storedInvoice.requestedServices.hourlyPrice.length;

      const requestedServicesObj = !isInvoice
        ? this.requestedServices
        : storedInvoice.requestedServices;

      const partsMaterialsObj = !isInvoice ? this.partsMaterials : storedInvoice.partsMaterials;

      const limitedWarrantyObj = !isInvoice ? this.limitedWarranty : storedInvoice.limitedWarranty;

      const handlingShippingObj = !isInvoice
        ? this.handlingShipping
        : storedInvoice.handlingShipping;

      const labour = +requestedServicesObj.labourQuantity * +requestedServicesObj.hourlyPrice;
      // Parts and materials
      const partsMaterials = +partsMaterialsObj.labourQuantity * +partsMaterialsObj.hourlyPrice;
      // Warranty
      const warranty =
        limitedWarrantyObj.hourlyPrice !== undefined
          ? +limitedWarrantyObj.labourQuantity * +limitedWarrantyObj.hourlyPrice
          : 0;
      // Shipping
      const shipping = +handlingShippingObj.labourQuantity * +handlingShippingObj.hourlyPrice;

      const total = labour + warranty + partsMaterials + shipping;
      return this.prices.subtotal === "" || +this.prices.subtotal < 1 || isInvoice
        ? total
        : this.prices.subtotal;
    },
    vatAmount() {
      return this.prices.vat;
    },
    subtotalPlusVat() {
      return +this.prices.vat + +this.subtotalPrice;
    },
    fixedFee() {
      return this.prices.fixedFee === undefined ? 9.0 : this.prices.fixedFee;
    },
    platformFee() {
      const fixedFee = this.prices.fixedFee === undefined ? 9.0 : this.prices.fixedFee;
      const result = +this.subtotalPrice * +this.prices.fee + +fixedFee;
      this.setPlatformFee(result);
      return result;
    },
    rows() {
      const quatation = this.$store.state.QuotationContractModal.serviceCurrency;
      const invoice = this.$store.state.InvoiceContractModal.serviceCurrency;
      const currency = quatation !== "" ? quatation : invoice;

      return [
        {
          title: this.$t("Subtotal"),
          disabled: true,
          value: this.subtotalPrice,
          setAction: this.setSubtotal
        },
        { title: this.$t("VAT"), disabled: false, value: this.prices.vat, setAction: this.setVat },
        {
          title: this.$t("Total"),
          disabled: false,
          value: `${this.$options.filters.currency(this.subtotalPlusVat)} ${currency}`,
          finalPrice: true
        },
        {
          title: this.$t("Platform fee"),
          disabled: false,
          value: `${this.$options.filters.currency(this.platformFee)} ${currency}`,
          finalPrice: true
        },
        {
          title: this.$t("Sum to be paid"),
          disabled: false,
          value: `${this.$options.filters.currency(this.prices.total)} ${currency}`,
          finalPrice: true
        }
      ];
    }
  },
  watch: {
    subtotalPrice() {
      this.totalPriceCount();
    },
    vatAmount() {
      this.totalPriceCount();
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setSubtotal",
      "setVat",
      "setTotal",
      "setFixedFee",
      "setPlatformFee"
    ]),
    totalPriceCount() {
      const total = +this.subtotalPrice + +this.platformFee + +this.vatAmount;
      this.setTotal(this.$options.filters.currency(total));
    }
  },
  mounted() {
    this.totalPriceCount();
  }
};
</script>

<style scoped>
.cm-summary {
  display: block;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
}
.cm-summary__table {
  width: 100%;
  max-width: 450px;
}
.cm-summary__table-cell {
  padding: 5px;
}
.cm-summary__table-cell:last-of-type {
  font-weight: bold;
}
.cm-summary__input {
  width: 150px;
}
</style>
