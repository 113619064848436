<script>
import { mapState, mapMutations } from "vuex";
import SummaryWarrantyRepair from "@/components/ContractModals/Components/BaseParts/SummaryWarrantyRepair";

export default {
  extends: SummaryWarrantyRepair,
  computed: {
    ...mapState("QuotationContractModal", ["prices"])
  },
  methods: {
    ...mapMutations("QuotationContractModal", ["setSubtotal", "setVat", "setTotal"])
  }
};
</script>
