<template>
  <div class="cm-handling-shipping">
    <div class="cm-handling-shipping__form">
      <div class="cm-handling-shipping__input-wrapper">
        <p class="input-label required">{{ $t("Labour / quantity") }}</p>
        <v-text-field
          type="number"
          min="0"
          :placeholder="$t('Labour / quantity')"
          solo
          :value="labourQuantity"
          @input="
            setHandlingShippingLabourQuantity($event);
            CheckRequiredFieldsFilledFunc();
          "
        />
      </div>
      <div class="cm-handling-shipping__select-wrapper">
        <p class="input-label required">{{ $t("Units") }}</p>
        <v-select
          solo
          :placeholder="$t('Select unit')"
          :items="['hours', 'pcs']"
          :value="unitsOne"
          @change="
            setHandlingShippingUnitsOne($event);
            CheckRequiredFieldsFilledFunc();
          "
        />
      </div>
      <div class="cm-handling-shipping__input-wrapper">
        <p class="input-label required">{{ $t("Hourly rate / price") }}</p>
        <v-text-field
          type="number"
          min="0"
          :placeholder="$t('Hourly rate / price')"
          solo
          :value="hourlyPrice"
          @input="
            setHandlingShippingHourlyPrice($event);
            CheckRequiredFieldsFilledFunc();
          "
        />
      </div>
      <div class="cm-handling-shipping__input-wrapper">
        <div class="cm-handling-shipping__unit-value">{{ unitsTwo | startCase }}</div>
      </div>
      <div v-if="price" class="cm-handling-shipping__price-wrapper">
        <table class="cm-handling-shipping__costs-table">
          <tr>
            <td class="cm-handling-shipping__costs-table-cell">{{ $t("Costs") }}:</td>
            <td class="cm-handling-shipping__costs-table-cell">{{ price }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="cm-handling-shipping__small-select-wrapper">
      <p class="input-label required">{{ $t("Incoterms") }}</p>
      <v-select
        solo
        :placeholder="$t('Incoterms')"
        :items="['EXW', 'CFR', 'CPT', 'CIF', 'FCA', 'FAS', 'FOB', 'DAT', 'DAP', 'DDP']"
        :value="incoterms"
        @change="
          setHandlingShippingIncoterms($event);
          CheckRequiredFieldsFilledFunc();
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { CheckRequiredFieldsFilled } from "@/components/ContractModals/Components/BaseParts/ModalValidation";

export default {
  props: {
    requiredFieldsFilled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState("QuotationContractModal", ["handlingShipping", "serviceCurrency"]),
    labourQuantity() {
      return this.handlingShipping.labourQuantity;
    },
    unitsOne() {
      return this.handlingShipping.unitsOne;
    },
    hourlyPrice() {
      return this.handlingShipping.hourlyPrice;
    },
    unitsTwo() {
      return this.handlingShipping.unitsTwo;
    },
    price() {
      const quatation = this.$store.state.QuotationContractModal.serviceCurrency;
      const invoice = this.$store.state.InvoiceContractModal.serviceCurrency;
      const currency = quatation !== "" ? quatation : invoice;
      return this.handlingShipping.price ? `${this.handlingShipping.price} ${currency}` : currency;
    },
    incoterms() {
      return this.handlingShipping.incoterms;
    }
  },
  methods: {
    ...mapMutations("QuotationContractModal", [
      "setHandlingShippingLabourQuantity",
      "setHandlingShippingUnitsOne",
      "setHandlingShippingHourlyPrice",
      "setHandlingShippingUnitsTwo",
      "setHandlingShippingIncoterms"
    ]),

    CheckRequiredFieldsFilledFunc() {
      const values = [this.labourQuantity, this.unitsOne, this.hourlyPrice, this.incoterms];
      const isFilled = CheckRequiredFieldsFilled(values);
      this.$emit("update:required-fields-filled", isFilled);
    }
  }
};
</script>

<style scoped>
.cm-handling-shipping {
  display: block;
  width: 100%;
}
.cm-handling-shipping__form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.cm-handling-shipping__input-wrapper,
.cm-handling-shipping__select-wrapper {
  width: 25%;
  max-width: 230px;
}
.cm-handling-shipping__price-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.cm-handling-shipping__costs-table-cell:last-of-type {
  font-weight: bold;
}
.cm-handling-shipping__small-select-wrapper {
  display: block;
  width: 100%;
  max-width: 265px;
  margin-top: 20px;
}
.cm-handling-shipping__unit-value {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
