<script>
import { mapState, mapMutations } from "vuex";
import PartsMaterials from "@/components/ContractModals/Components/BaseParts/PartsMaterials";

export default {
  extends: PartsMaterials,
  computed: {
    ...mapState("PurchaseOrderSt2ContractModal", ["partsMaterials"])
  },
  methods: {
    ...mapMutations("PurchaseOrderSt2ContractModal", [
      "setPartsMaterialsLabourQuantity",
      "setPartsMaterialsUnitsOne",
      "setPartsMaterialsHourlyPrice",
      "setPartsMaterialsUnitsTwo"
    ])
  }
};
</script>
